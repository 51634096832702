.pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .page-link {
    display: inline-block;
    padding: 16px 24px;
    margin: 0 4px;
    color: #fff;
    border: 3px solid #72daab;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none; /* Remove black dots on the buttons */
    appearance: none;
  }
  
  .page-link:hover {
    background-color: #bfe8c3;
  }
  .page-link.active {
    background-color: #bfe8c3;
    color: #fff;
  }