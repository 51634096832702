.authentication-container{
    display: flex;

   justify-content: center;

   


}



h2 {
    margin: 10px 0;
    font-size: 1em;
  }