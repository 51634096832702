.sign-up-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 0px 100px 100px 0px;
    font-size: 1em;
    margin-left:75px ;
    color: white;

  

  
    h2 {
      margin: 10px 0;
      font-size: 1em;
      color: white;
    }
  

    .buttons-container {

        display: flex;
        justify-content: space-between;
    }
}