
body {
  background-color: #202220;
  height: 200vh !important;
  margin: 0;
  font-family: 'Oxygen';
  padding: 0px !important;
  
  
}

a {
  text-decoration: none;
  color: rgb(101, 100, 100);
}
* {
 

  box-sizing: border-box;
} 


