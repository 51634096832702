

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #333;
  color: white;
  transition: all 0.3s ease;
 
}
  
    .logo-container {
      height: 100%;
      width: 70px; 
      
     
 }
 
  
    .nav-links-container {
      width: 60%;
      height: 100%;
      display: flex;
     align-items: center;
      justify-content: center;
      

}
  
  

      .nav-footer {
        
        cursor: pointer;
        color: #fff;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
     
       
      }

      .nav-link-join-now {
        padding:35px 20px 35px 20px;
     
        line-height: 5em;
       background-color:#3cc88b;
       color: #fff;
       border: 1px solid grey; 
       border-radius: 8px;
    
        font-size:medium;


        &:hover {
          background-color: white;
          color: black;
          border: 1px solid black;

      }}
      .nav-link-membership {
        padding:43px 43px 43px 43px;
        margin: 0px 5px 0px 5px;
        background-color: #3cc88b;
        line-height: 1em;
     
       border-radius: 8px;
      
       color: white !important;
      position: relative;
       font-size: 1em;
       float: right;
       

       &:hover {
        background-color: #bfe8c3;
      
        color: black !important;
     

          }}
    
      .nav-link-login {
        background: #202220;
        padding:40px 40px 40px 40px;
        margin: 0px 5px 0px 5px;
        color: white !important;
        line-height: 1em;
        border: 3px solid #72daab !important;
       border-radius: 8px;
       position:relative;
       float: right;

       &:hover {
        background-color: #bfe8c3;
      
        color: black !important;
     

          }}
          .nav-link-blog {
            background: #202220;
            padding:40px 40px 40px 40px;
            margin: 0px 5px 0px 5px;
            color: white !important;
            line-height: 1em;
           border-radius: 8px;
           position:relative;
           float: right;
    }
    

      
      


       
    .nav-link-contact 
      {
        background: #202220;
        padding:40px 40px 40px 40px;
        margin: 0px 5px 0px 5px;
        color: white !important;
        line-height: 1em;

       border-radius: 8px;
       position:relative;
       float: right;

    
        
   }
       
    
    
    
        
        .nav-links {
         float: right;
          color: white;
          text-decoration: none;
        }
        
        .mobile-menu-icon {
          display: none;
          cursor: pointer;
        }
        
        .mobile-menu {
          display: none;
        }
        
        /* Mobile Navbar styles */
        .mobile {
          flex-direction: column;
          
        }
        
        @media (max-width: 992px) {
          .navigation {
            padding: 20px;

            
          }
          .nav-link-contact-mobile{
            padding: 20px 20px 20px 20px;
            margin-top: 80px;
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: white;
            background-color: none !important;
            
           
          }
          .nav-link-membership-mobile{
            padding: 20px 20px 20px 20px;
            margin-top: 25px;
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: white;
            background-color: none !important;
         
          }
          .nav-link-blog-mobile{
            padding: 20px 20px 20px 20px;
            margin-top: 135px;
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: white ;
            background-color: none !important;
            
           
          }
        
          .mobile-menu-icon {
            display: block;
          }
        
          .nav-links {
            display: none;
          }
        
          .mobile-menu {
            display: flex;
            flex-direction: column;
            background-color: #303330;
            position: absolute;
            height: 32vh;
            top: 0px;
            left: 0;
            right: 0;
            padding: 15px;
            z-index: 1;
          }
        
          .mobile-menu-item {
            color: white;
            margin: 8px 0;
            cursor: pointer;
          }
        
          .mobile-menu-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 24px;
            cursor: pointer;
            z-index: 2;
          }
      
       .logo {
        height: 100%;
        width: 100px; 
        margin: auto;
        
       
   }
        }
        
      
 
      

    
      
     