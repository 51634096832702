//Member sign up and sign in form button change size here
.button-container {
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 25px;
    padding: 10px 10px 10px 10px;
    border-radius: 2px;
    font-size: 15px;
    background-color: rgb(16, 17, 16);
    color: white;
    text-transform: uppercase;
    font-family: 'Oxygen';
    font-weight: bolder;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  
    &.google-sign-in {
      background-color: #bfe8c3;
      color: #242424;
  
      &:hover {
        background-color: #dbe2ec;
        border: none;
      }
    }
  
    &.inverted {
      background-color: white;
      color: black;
      border: 1px solid black;
  
      &:hover {
        background-color: black;
        color: white;
        border: none;
      }
    }
    .button--medium {
      padding: 8px 20px;
      font-size: 18px;
    }
    
    .button--large {
      padding: 12px 26px;
      font-size: 20px;
    }
  }
  