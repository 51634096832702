.search-box {
    -webkit-appearance: none;
    background-color: #202220;
    border: 3px solid #72daab;
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
    width: 500px;
    line-height: 25px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    outline-color: none;
    
  }
  @media (max-width: 992px) {
.search-box{
  width: 300px;

}
    

  
  }